<template>
  <div class="copyright mb-4 text-body-2">
    <div :class="`${textColor}--text`" style="display: flex; justify-content: center">
      <div>
        <a :class="`${textColor}--text`" href="https://beian.miit.gov.cn/" title="粤ICP备2021056281号">粤ICP备2021056281号</a>
      </div>
      <v-divider vertical :class="`mx-3 ${textColor}`" />
      <div>Copyright © {{ new Date().getFullYear() }} The Nielsen Company (US), LLC. All rights reserved. Confidential and Proprietary</div>
    </div>
    <div v-if="!isProductEnv">version {{ prodVersion }}</div>
  </div>
</template>

<script>
import { isProductEnv, prodVersion } from '@/config'

export default {
  props: {
    textColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      isProductEnv: isProductEnv,
      prodVersion: prodVersion
    }
  },
}
</script>

<style>

</style>